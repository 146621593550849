import React from "react";
import FlavoredHoney from "src/components/Pages/ListHoney/ListHoney"
import {graphql} from "gatsby";
import PropTypes from "prop-types";


const OffertPage = ({data}) => {
    const Flavored = data.allDatoCmsOffer.nodes;
    return (
        <>
            <FlavoredHoney Data={Flavored} categor="flavored" title='Miody smakowe'/>
        </>
    )
}
export default OffertPage

OffertPage.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    query {
        allDatoCmsOffer(filter: {category: {eq: "miody_smakowe"}}, sort: {fields: title}) {
            nodes {
                id
                title
                slug
                description
                price
                category
                capacity {
                    choicecapacity
                }
                fotoOffer {
                   
                    gatsbyImageData(
                        placeholder: BLURRED
                        forceBlurhash: false
                        layout: CONSTRAINED
                    )
                
                }
            }
        }
    }

`